import React from 'react'
import Header from './Component/Header'
import Options from './Component/Options'
import "./App.css"

const App = () => {
  return <>
    <Header />
    <main>
      <Options />
    </main>
  </>
  
}

export default App