import React from "react";
import { Link } from "react-router-dom";
import "./Options.css";

const Button = ({ title, route }) => {
  return (
    <Link to={route}>
      <button className="option">{title}</button>
    </Link>
  );
};

const Options = () => {
  return (
    <section className="options-section">
      <Button
        title={"Admin Login"}
        route={"https://admission-akgec-admin.brlakgec.com/"}
      />

      <Button
        title={"Give Test"}
        route={"https://admission-akgec-test.brlakgec.com/"}
      />
    </section>
  );
};

export default Options;
