import "./Header.css"

const Header = () => {
    const brlLogo = "/logo-color-png.png"
    const akgLogo = "/akgec-logo.png"
  return (
    <header>
        <img src={akgLogo} alt="college logo" className="college-logo"/>
        <img src={brlLogo} alt="college logo" className="brl-logo"/>
    </header>
  )
}

export default Header